<template>
  <div id="addWaterSupply">
    <b-modal
      id="modal-center"
      v-model="showModal"
      modal-class="custom-modal-defualt"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      size="xl"
      @change="onChange"
      @shown="show"
    >
      <template #modal-title="{}">
        <font-awesome-icon :icon="['fas', 'clipboard-check']" class="mr-1" />
        หลักเกณฑ์การประเมิน
      </template>

      <template v-slot:default="">
        <div class="assessment-table">
          <b-table-simple class="table-custom">
            <b-thead>
              <b-tr>
                <b-th colspan="2"></b-th>
                <b-th class="text-center">คะแนน</b-th>
                <b-th class="text-center">เปอร์เซ็นต์</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(item, index) in items" :key="`item-${index}`">
                <b-td>{{ item.resultDesciption }}</b-td>
                <b-td
                  class="text-center"
                  :class="getBackgroundColor(item.symbol)"
                  >{{ item.symbol }}</b-td
                >
                <b-td class="text-center">{{
                  item.scoreFrom + "-" + item.scoreTo
                }}</b-td>
                <b-td class="text-center">{{
                  item.scorePercentageFrom + "-" + item.scorePercentageTo
                }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </template>

      <template #modal-footer="{ cancel }">
        <div class="d-flex w-100 justify-content-center my-2">
          <button class="btn btn-outline-save" @click="cancel()">ตกลง</button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { AssessmentService } from "@/services/main.service.js";
export default {
  props: {
    modalShow: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      showModal: false,
      items: [],
      fields: [
        { key: "resultDesciption", label: "", align: "left" },
        { key: "symbol", label: "", align: "center" },
        { key: "scoreFrom", label: "คะแนน", align: "center" },
        { key: "scorePercentageFrom", label: "เปอร์เซ็นต์", align: "center" },
      ],
    };
  },
  async created() {
    await AssessmentService.getSurveyResultRange().then((res) => {
      this.items = res.map((m) => {
        return {
          ...m,
        };
      });
    });
  },
  methods: {
    show() {
      console.log("test show");
      const el = document.querySelector("#modal-center");
      console.log("el: ", el);
      const test = el.querySelectorAll(".modal-content");
      console.log("test: ", test);
      test[0].removeAttribute("tabindex");
    },
    onChange() {
      if (!this.showModal) {
        this.$emit("change", this.showModal);
      }
    },
    ok() {
      console.log("ok");
    },
    cancel() {
      console.log("cancel");
    },
    getBackgroundColor(bg) {
      if (bg === "A") return "bg-exellent";
      else if (bg === "B") return "bg-good";
      else if (bg === "C") return "bg-enough";
      else if (bg === "D") return "bg-so";
      else if (bg === "F") return "bg-bad";
    },
  },
  watch: {
    modalShow: {
      handler: function (val, oldVal) {
        if (val) {
          this.showModal = true;
        }
      },
    },
    deep: true,
  },
};
</script>

<style lang="scss">
.bg-exellent {
  background-color: $graph-green;
}
.bg-good {
  background-color: $graph-softgreen;
}
.bg-enough {
  background-color: $graph-yellow;
}
.bg-so {
  background-color: $graph-orange;
}
.bg-bad {
  background-color: $graph-rad;
}

.assessment-table {
  font-weight: 700;
}
</style>
